import '../node_modules/photoswipe/dist/photoswipe.css';
import '../node_modules/photoswipe/dist/default-skin/default-skin.css';
import './main.scss';
import "@babel/polyfill";

import Swiper from 'swiper';
import CookiesEuBanner from 'cookies-eu-banner';
import _, { isNumber } from 'lodash';
import $ from 'jquery';
import 'popper.js';
import 'bootstrap';
import bsCustomFileInput from 'bs-custom-file-input'
import '@ryangjchandler/spruce'
import './js/photoswipe.js';

import Vue from 'vue';
//import NewsApp from './js/NewsApp.vue';
import Countable from 'countable';


require('alpinejs/dist/alpine-ie11.js');


window.toggleExtraFieldChecked = function (initial, condition) {
  return {
    value: initial,
    isOpen() {
      return condition(this.value);
    },
    init() {
      if (Array.isArray(initial)) {
        this.value = [...this.$el.querySelectorAll('[x-model="value"]:checked')].map(node => node.value);
      } else {
        let node = this.$el.querySelector('[x-model="value"]:checked');
        if (node) {
          this.value = node.value;
        }
      }
    },
  }
}


window.toggleProjectClass = function (initial, labels, option_1, option_2) {
  const changeTexts = (value, el, key) => {
    const label = labels[value][key].label;
    const help_text = labels[value][key].help_text;

    let helpEl = el.querySelector('.form-text');
    let labelEl = el.querySelector('label');

    if (helpEl) {
      helpEl.innerHTML = help_text;
    }

    if (labelEl) {
      labelEl.innerHTML = label;
    }
  }

  return {
    project_class: initial,
    option() {
      if (this.project_class === option_1) return 1;
      if (this.project_class === option_2) return 2;
      return 0;
    },
    init() {
      if (Array.isArray(initial)) {
        this.project_class = [...this.$el.querySelectorAll('[x-model="project_class"]:checked')].map(node => node.value);
      } else {
        let node = this.$el.querySelector('[x-model="project_class"]:checked');
        if (node) {
          this.project_class = node.value;
        }
      }
      const textChanger = value => {
        if (this.option() in labels) {
          changeTexts(this.option(), this.$refs['project_title'], 'project_title');
          changeTexts(this.option(), this.$refs['description'], 'description');
          changeTexts(this.option(), this.$refs['project_upload'], 'project_upload');
          changeTexts(this.option(), this.$refs['project_links'], 'project_links');
        }
      }
      this.$watch('project_class', textChanger);
      textChanger();
    },
  }
}


window.toggleInnerExtraFieldChecked = function (initial, condition) {
  return {
    innerValue: initial,
    isOpen() { return condition(this.innerValue); },
    init() {
      if (Array.isArray(initial)) {
        this.innerValue = [...this.$el.querySelectorAll('[x-model="innerValue"]:checked')].map(node => node.value);
      } else {
        let node = this.$el.querySelector('[x-model="innerValue"]:checked');
        if (node) {
          this.innerValue = node.value;
        }
      }
    },
  }
}


window.toggleMultiFields = function (initial, condition, selectCondition) {
  return {
    innerValue: initial,
    isOpen() { return condition(this.innerValue); },
    isSelected() { return selectCondition(this.innerValue); },
    init() {
      if (Array.isArray(initial)) {
        this.innerValue = [...this.$el.querySelectorAll('[x-model="innerValue"]:checked')].map(node => node.value);
      } else {
        let node = this.$el.querySelector('[x-model="innerValue"]:checked');
        if (node) {
          this.innerValue = node.value;
        }
      }
    },
  }
}


window.toggleFeedBackField = function () {
  return {
    value: '',
    rating: null,
    open: false,
    isOpen() { return this.value || this.open; },
    init() {
      let node = this.$el.querySelector('[x-model="value"]');
      if (node) {
        this.value = node.value;
      }

      let rating = this.$el.querySelector('[x-model="rating"]:checked');
      if (rating) {
        this.rating = rating.value;
      }
    }
  }
}

window.toggleExtraFieldSelected = function (initial, condition) {
  return {
    value: initial,
    isOpen() { return condition(this.value); },
    init() {
      if (Array.isArray(initial)) {
        this.value = [...this.$el.querySelectorAll('[x-model="value"] option[selected]')].map(node => node.value);
      } else {
        this.value = this.$el.querySelector('[x-model="value"] option[selected]').value;
      }
    },
  }
}


window.TotalRating = function (initialRating) {
  // {rating: {'fgdfgg': {'rating': x, 'weight': x}}}
  return {
    rated() { return _.filter(_.values(this.rating), o => o.rating !== null); },
    count() {
      //console.log(this.rating);
      return this.rated().length;
    },
    total() {
      let totalWeights = 1; //_.sumBy(this.rated(), o => o.weight);
      let total = _.sumBy(this.rated(), o => o.weight * o.rating) / totalWeights;
      let extraPoints = this.extra ? 20 : 0;
      return (total * 100 / 4) + extraPoints;
    },
    update(newRating) {
      // {label: 'fgdfgg', rating: x}
      if (newRating.label in this.rating) this.rating[newRating.label].rating = parseInt(newRating.rating)
    },
    init() {
      let extra = this.$el.querySelector('[x-model="extra"]:checked');
      if (extra) {
        this.extra = true;
      }
    },
    extra: false,
    ...initialRating
  }
}

window.CountingField = function (limit, direction, ressource) {
  return {
    limit: limit,
    direction: direction || 'down',
    ressource: ressource || 'all',
    counter: 0,
    count() { return (this.direction === 'down') ? this.limit - this.counter : this.counter },
    reached() { return (this.direction === 'down') ? this.counter === 0 : this.counter >= this.limit },
    init() {
      let node = this.$refs.field;
      if (node) {
        Countable.on(node, counter => { this.counter = counter[this.ressource]})
      }
    }
  }
}

window.InlineForms = function (params) {
  return {
    template: null,
    add() {
      let container = document.getElementById(this.container);
      container.insertAdjacentHTML('beforeend', this.template(this.TOTAL_FORMS));
      this.TOTAL_FORMS++;
    },
    loadTemplate(id) {
      let template = document.getElementById(id);
      this.template = (id) => template.innerHTML.replace(/__prefix__/g, id).replace(/__number__/g, id + 1);
    },
  }
};

window.Spruce.store('preview', {
  active: null,
  fullscreen: false,
  app: {id: '', rank: '', name: ''},
  pages: [],

  setData(pages, app) {
    this.pages = pages;
    this.app = app;
    this.active = '#app';
  },

  activeUrl() {
    const active_page = this.pages.find(page => page.hash === this.active);
    return active_page ? active_page.url: null;
  },

  // rating-preview
});

const preview = Spruce.store('preview')

window.PreviewIframe = function () {
  return {
    init() {
      let that = this;
      window.Spruce.watch(
        'preview.active', () => that.$el.contentWindow.location.replace(preview.activeUrl())
      );
    }
  }
}


window.addEventListener('load', () => {
  try {
    var photogalleryOptions = {
      shareButtons: localizedShareButtons
    };
  } catch (ReferenceError) {
    var photogalleryOptions = {};
  }

  $.extend(photogalleryOptions, {
    itemFilter: function () {
      return $(this).parents('.slick-cloned').length === 0;
    },
    captionSelector: '.caption'
  });

  $('.gallery').photogallery(photogalleryOptions);

  bsCustomFileInput.init();

  $('#nav-tab a').on('click', function (e) {
    e.preventDefault()
    $(this).tab('show')
  });

  $('.popup__links').on('click', function (e) {
    e.preventDefault();
    var modal = $(this).data("target");
    $(modal).modal("show");
    $(modal + ' .modal-body').load(
      $(this).attr("href") + ' .text-page-container',
      function () {
      }
    );
  });


  $('body').scrollspy({
    target: '#navbar-main'
  });

  if ($('.swiper-slideshow .swiper-slide').length > 1) {

    var gallerySwiper = new Swiper('.swiper-slideshow', {
      slidesPerView: 1,
      speed: 500,
      navigation: {
        prevEl: '.swiper-slideshow__arrow--prev',
        nextEl: '.swiper-slideshow__arrow--next',
        disabledClass: 'swiper-slideshow__arrow--disabled',
      },
      pagination: {
        el: '.swiper-pagination',
        type: 'progressbar',
        clickable: true,
      },
      autoplay: {
        delay: 5000,
        disableOnInteraction: true,
      },
    });

    $('.swiper-slideshow').hover(function (params) {
      gallerySwiper.autoplay.stop();
    }, function (e) {
      gallerySwiper.autoplay.start();
    });
  }

  var file = document.getElementById("id_moodboard");
  if (file) {
    file.onchange = function () {
      if (file.files.length > 0) {

        document.getElementsByClassName('file-name')[0].innerHTML = file.files[0].name;

      }
    };
  }
});

(function () {
  function CountableField(textarea) {
    var countDisplay = document.getElementById(textarea.id + "_counter");
    var countDown = false;
    var minCount, maxCount;
    if (textarea != null && countDisplay != null) {
      minCount = textarea.getAttribute("data-min-count");
      maxCount = textarea.getAttribute("data-max-count");

      Countable.on(textarea, updateCharatersLeft);
    }

    function updateCharatersLeft(counter) {
      var count = counter["all"];
      var left = maxCount - count;
      countDisplay.getElementsByClassName("text-count-current")[0].innerHTML = left;
      if (left < 0)
        countDisplay.className = "text-count text-is-over-max";
      else
        countDisplay.className = "text-count";
    }
  }

  document.addEventListener('DOMContentLoaded', function (e) {
    ;
    [].forEach.call(document.querySelectorAll('[data-count]'), CountableField)
  })
})()
